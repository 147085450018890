import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const TriAgem = ({ data }) => {
  return (
    <Layout>
      <SEO title="Introducing triAgem - Venue Sign In System" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap items-start w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              Introducing
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">triAgem</h3>
            <p className="mb-4">
              triAgem has been developed with our customers requirements and
              operational workflow as the highest priority. The triAgem Member
              &amp; Visitor management system is an all-inclusive package that
              is fully customisable. In addition to its advanced entry
              management and reporting functionality, triagem devices can also
              be used to capture and alert high temperature readings, staff
              attendance management, loyalty kiosk functionality and much more.
            </p>

            <h3 className="mb-6 text-3xl font-bold leading-none">Features</h3>

            <Accordion
              allowMultipleExpanded
              allowZeroExpanded
              preExpanded={['0']}
            >
              <AccordionItem uuid="0">
                <AccordionItemHeading>
                  <AccordionItemButton>The Cord Features</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td>Infrared temperature measurement.</td>
                      </tr>
                      <tr>
                        <td>Customer interaction and communication.</td>
                      </tr>
                      <tr>
                        <td>Real time analysis of data and alerting.</td>
                      </tr>
                      <tr>
                        <td>
                          Supports facial recognition, QR code scan, id caro
                          scan &amp; card swipe
                        </td>
                      </tr>
                      <tr>
                        <td>Smart wide dynamic recognition camera.</td>
                      </tr>
                      <tr>
                        <td>Customer Counting, Tracking and Reporting</td>
                      </tr>
                      <tr>
                        <td>
                          Sign in / Sign out Functions with Secure OTP
                          verification
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    The Intelligence Features
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td>
                          Inbuilt facial recognition algorithm based on deep
                          learning and intelligence.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Fast processor greatly improving the face capture
                          rate.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Real-time temperature detection, high temperature
                          alarm and paging.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Self-serve registration using Card identification and
                          ID capture registration process
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Enhanced Face Image Capturing, reporting and matching
                          ability.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    The Supporting Features
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td>
                          Camera Coverage area is accurate for wide angles and
                          supports between 0.3 -0.8m.
                        </td>
                      </tr>
                      <tr>
                        <td>Temperature management accuracy +0.2 °C~ +0.5°C</td>
                      </tr>
                      <tr>
                        <td>
                          This Unit can also work as a Standalone unit with its
                          own small database.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          The kiosk can be mounted on the wall, Desk or floor
                          Standing.
                        </td>
                      </tr>
                      <tr>
                        <td>Supports cloud and local hosting.</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:mt-0 md:pl-8 md:w-1/2 lg:w-1/3">
          <div className="px-4 mx-auto md:w-full">
            <video
              autoPlay
              loop
              controls
              className="w-full max-w-xs px-4 mx-auto "
            >
              <source src="/triagem-video.webm" type="video/webm" />
              <source src="/triagem-video.mp4" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
            <Image
              className="w-full max-w-xs mx-auto mt-8 lg:w-64 "
              fluid={data.TriAgem.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

TriAgem.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query TriAgemQuery {
    TriAgem: file(relativePath: { eq: "triagem-device.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default TriAgem;
